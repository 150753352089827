import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/purchasing-department',
    name: 'PurchasesFormsList',
    component: () => import('../views/Purchases-dept/PurchaseMain'),
    meta: {
      breadcrumb: 'Отдел закупок'
    },
    children: [
      {
        path: 'purchase',
        component: () => import('../views/Purchases-dept/purchase/Purchase'),
        meta: {
          breadcrumb: 'Закупка'
        }
      },
      {
        path: 'request-for-prices',
        component: () => import('../views/Purchases-dept/request-for-prices/RequestForPrices'),
        meta: {
          breadcrumb: 'Запрос цен'
        }
      },
      {
        path: 'registration',
        component: () => import('../views/Purchases-dept/registration/ProjectRegistration'),
        meta: {
          breadcrumb: 'Регистрация проекта'
        }
      },
      {
        path: '',
        component: () => import('../views/Purchases-dept/PurchasesDepFormsList')
      }
    ]
  },
  {
    path: '/logistics',
    name: 'LogisticsFormList',
    component: () => import('../views/Logistics-dept/LogisticsMain'),
    meta: {
      breadcrumb: 'Отдел логистики Полисервис'
    },
    children: [
      {
        path: 'sending-documents',
        component: () => import('../views/Logistics-dept/sending-documents/SendingDocuments'),
        meta: {
          breadcrumb: 'Доставка документов'
        }
      },
      {
        path: 'delivery-equipment',
        component: () => import('../views/Logistics-dept/delivery-equipment/DeliveryEquipment'),
        meta: {
          breadcrumb: 'Доставка оборудования'
        }
      },
      {
        path: 'order-workers',
        component: () => import('../views/Logistics-dept/order-workers/Workers'),
        meta: {
          breadcrumb: 'Заказ грузчиков'
        }
      },
      {
        path: '',
        component: () => import('../views/Logistics-dept/LogisticsDepFormList')
      }
    ]
  },
  {
    path: '/bookkeeping',
    name: 'BookkeepingFormList',
    component: () => import('../views/Bookkeeping-dept/BookkeepingDeptMain'),
    meta: {
      breadcrumb: 'Бухгалтерия Полисервис'
    },
    children: [
      {
        path: 'issuance-of-funds',
        component: () => import('../views/Bookkeeping-dept/issuance-of-funds/IssuanceOfFunds'),
        meta: {
          breadcrumb: 'Выдача денежных средств'
        }
      },
      {
        path: 'compensation',
        component: () => import('../views/Bookkeeping-dept/compensation/Compensation'),
        meta: {
          breadcrumb: 'Компенсация расходов'
        }
      },
      {
        path: 'business-trip',
        component: () => import('../views/Bookkeeping-dept/business-trip/BusinessTrip'),
        meta: {
          breadcrumb: 'Оформление командировки'
        }
      },
      {
        path: 'absent',
        component: () => import('../views/Bookkeeping-dept/absent/Absent'),
        meta: {
          breadcrumb: 'Оформление отсутствия'
        }
      },
      {
        path: 'vacation',
        component: () => import('../views/Bookkeeping-dept/vacation/Vacation'),
        meta: {
          breadcrumb: 'Оформление отпуска'
        }
      },
      {
        path: 'payment',
        component: () => import('../views/Bookkeeping-dept/payment/Payment'),
        meta: {
          breadcrumb: 'Оплата счетов'
        }
      },
      {
        path: 'new-employee',
        component: () => import('../views/Bookkeeping-dept/new-employee/NewEmployee'),
        meta: {
          breadcrumb: 'Оформление нового сотрудника'
        }
      },
      {
        path: '',
        component: () => import('../views/Bookkeeping-dept/BookkeepingDepFormList')
      }
    ]
  },
  {
    path: '/projects',
    name: 'ProjectsFormList',
    component: () => import('../views/Projects/ProjectMain'),
    meta: {
      breadcrumb: 'Реализация проектов'
    },
    children: [
      {
        path: 'planning-to-schedule',
        component: () => import('../views/Projects/resource/PlanningToSchedule'),
        meta: {
          breadcrumb: 'Планирование в график отсутствий'
        }
      },
      {
        path: 'request-resource',
        component: () => import('../views/Projects/resource/RequestResource'),
        meta: {
          breadcrumb: 'Запрос ресурсов'
        }
      },
      {
        path: 'consumables',
        component: () => import('../views/Projects/consumables/Consumables.vue'),
        meta: {
          breadcrumb: 'Запрос расходных материалов'
        }
      },
      {
        path: 'business-trip',
        component: () => import('../views/Bookkeeping-dept/business-trip/BusinessTrip'),
        meta: {
          breadcrumb: 'Оформление командировки'
        }
      },
      {
        path: '',
        component: () => import('../views/Projects/ProjectsFormList')
      }
    ]
  },
  {
    path: '/planning-to-schedule',
    name: 'Planning',
    component: () => import('../views/Planning')
  },
  {
    path: '/polyled',
    name: 'Resources',
    component: () => import('../views/Polyled')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
