<template>
  <div>
<!--    <router-link-->
<!--      v-for="crumb in crumbs"-->
<!--      :key="crumb.path"-->
<!--      :to="crumb.to"-->
<!--    >{{crumb.text}} | </router-link>-->
    <v-breadcrumbs
      :items="crumbs"
      divider="/"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :disabled="item.disabled"
          exact
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
<!--    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>-->
<!--    <v-breadcrumbs-item-->
<!--      v-for="item in crumbs"-->
<!--      :key="item.path"-->
<!--      :to="item.to"-->
<!--    >-->
<!--      {{item}}-->
<!--    </v-breadcrumbs-item>-->
  </div>
</template>

<script>
export default {
  name: 'Bread',
  data: () => ({
    items: []
  }),
  computed: {
    crumbs: function() {
      const pathArray = this.$route.path.split('/')
      pathArray.shift()
      // const homeCrumb = [
      //   {
      //     path: '/',
      //     to: '/',
      //     text: 'Home'
      //   }
      // ]
      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? '/' + breadcrumbArray[idx - 1].path + '/' + path
            : '/' + path,
          text: this.$route.matched[idx]?.meta.breadcrumb || path,
          disabled: idx === pathArray.length - 1
        })
        return breadcrumbArray
      }, [])
      console.log(breadcrumbs)
      return breadcrumbs
    }
  },
  watch: {
    '$route'() {
      this.getRoute()
      console.log(this.$route)
    }
  },
  methods: {
    getRoute() {
      this.items = this.$route.matched
    }
  },
  created() {
    this.getRoute()
  }
}
</script>

<style scoped>

</style>
