import Bitrix from '../../../plugins/Bitrix'

export default {
  actions: {
    async addSendDocsRequest (ctx, data) {
      try {
        const files = await Bitrix.loadFiles(data.files)
        let observers = []
        if (data.observers) {
          observers = data.observers.map(item => item.id)
        }
        const params = {
          IBLOCK_TYPE_ID: 'lists_socnet',
          IBLOCK_ID: '177',
          ELEMENT_CODE: Date.now(),
          FIELDS: {
            NAME: 'Отправка документов',
            PROPERTY_1429: data.sender.title, // компания отправитель
            PROPERTY_1479: data.recipient.companyInfo.title, // компания получатель
            PROPERTY_1191: data.recipient.address, // адрес получателя
            PROPERTY_1193: data.recipient.contactName, // контактное лицо получателя
            PROPERTY_1313: data.recipient.phoneNumber, // телефон получателя
            PROPERTY_1325: data.atWhoseExpenseSelect, // за чей счет отправка
            PROPERTY_1327: data.buyerOrder, // заказ покупателя
            PROPERTY_1197: data.date,
            PROPERTY_1195: data.delivery,
            DETAIL_TEXT: data.deliveryComment,
            PROPERTY_1183: data.deal ? data.deal.id : '', // сделка
            PROPERTY_1181: files, // файл
            PROPERTY_1483: observers, // наблюдатели
            PROPERTY_1187: data.taskId, // номер родительской задачи
            PROPERTY_1707: 'application'
          }
        }
        const res = await Bitrix.callMethod('lists.element.add', params)
        return { elementId: res }
      } catch (e) {
        return e
      }
    }
  }
}
